import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import fetch from 'cross-fetch';
import { setContext } from 'apollo-link-context';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { useUser } from '@luigiclaudio/ga-auth-theme';

const Apollo = ({ children }) => {
    const [user] = useUser();

    const accessToken = get(user, 'token.access_token');

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: accessToken ? `Bearer ${accessToken}` : '',
            },
        };
    });

    const httpLink = new HttpLink({
        uri: `${process.env.GATSBY_SITE_BASE_URL}/.netlify/functions/graphql`,
        fetch,
    });

    const client = new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        allColors: {
                            data: {
                                merge: false,
                            },
                        },
                    },
                },
            },
        }),
        link: authLink.concat(httpLink),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

Apollo.propTypes = {
    children: PropTypes.node,
};

Apollo.defaultProps = {
    children: undefined,
};

export default Apollo;
