import styled from 'styled-components';
import { rem } from 'polished';

const CONTAINER_MIN_WIDTH = 160;
const EXTRA_OFFSET_TOP_HEADER = 16;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    min-width: ${rem(CONTAINER_MIN_WIDTH)};
    position: fixed;
    top: ${({ theme, offsetTop }) =>
        offsetTop && !Number.isNaN(offsetTop)
            ? rem(offsetTop)
            : rem(theme.header.height + EXTRA_OFFSET_TOP_HEADER)};
    transform: translateX(-50%);
    z-index: 999;
`;

export default Container;
