import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { mailchimpSubscribeI18nEnUs } from '@luigiclaudio/ga-mailchimp-signup';
import { validationI18nEnUs } from '@luigiclaudio/ga-baseline-ui/validation';
import baselineUIMessagesEnUs from '../../i18n/baselineUIMessagesEnUs';
import siteMessagesEnUs from '../../i18n/siteMessagesEnUs.json';
import supportMessagesEnUs from '../../i18n/supportMessagesEnUs.json';
import authMessagesEnUs from '../../i18n/authMessagesEnUs';
import siteNavigationEnUs from '../../i18n/siteNavigationEnUs.json';

const messagesEnUs = {
    ...baselineUIMessagesEnUs,
    ...mailchimpSubscribeI18nEnUs,
    ...validationI18nEnUs,
    ...siteNavigationEnUs,
    ...authMessagesEnUs,
    ...siteMessagesEnUs,
    ...supportMessagesEnUs,
};

const SiteIntlProvider = ({ children }) => {
    return (
        <IntlProvider key="en-US" locale="en-US" messages={messagesEnUs} defaultLocale="en-US">
            {children}
        </IntlProvider>
    );
};

SiteIntlProvider.propTypes = {
    children: PropTypes.node,
};

SiteIntlProvider.defaultProps = {
    children: undefined,
};

export default SiteIntlProvider;
