import styled, { css } from 'styled-components';

const AuthContentContainer = styled.div`
    ${({ currentPath, hasMounted }) => {
        const authPath =
            currentPath &&
            (currentPath.includes('/signIn') ||
                currentPath.includes('/signUp') ||
                currentPath.includes('/resetPassword') ||
                currentPath.includes('/recoverPassword') ||
                currentPath.includes('/recoverAccount') ||
                currentPath.includes('/confirmAccount'));

        return (
            authPath &&
            hasMounted &&
            css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 100%;
            `
        );
    }}
`;

export default AuthContentContainer;
