import siteTypographyTheme from './siteTypographyTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';

const siteTypographyDarkTheme = {
    typography: {
        ...siteTypographyTheme.typography,
        colors: {
            dark: siteBaseDarkTheme.base.colors.dark,
            muted: siteBaseDarkTheme.base.colors.muted,
        },
    },
};

export default siteTypographyDarkTheme;
