import '@fontsource/outfit/300.css';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/600.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/600.css';
import siteBaseTheme from './siteBaseTheme';

const siteTypographyTheme = {
    typography: {
        base: {
            fontFamily: 'Outfit, sans-serif',
            fontSizeMin: 14,
        },
        headings: {
            fontFamily: 'Outfit, sans-serif',
            fontSize: {
                h6: 15,
            },
            fontWeight: {
                h1: 600,
                h2: 300,
                h3: 300,
                h4: 400,
                h5: 500,
                h6: 500,
            },
            lineHeight: 1.15,
        },
        largeHeadings: {
            lineHeight: {
                h1: 1,
                h2: 1.15,
            },
        },
        strong: {
            fontWeight: 600,
        },
        colors: {
            dark: siteBaseTheme.base.colors.dark,
            light: siteBaseTheme.base.colors.light,
        },
    },
};

export default siteTypographyTheme;
