import React from 'react';
import PropTypes from 'prop-types';
import { BaselineThemeProvider } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { MountedProvider } from '@luigiclaudio/ga-baseline-ui/helpers';
import { ModalProvider } from '@luigiclaudio/ga-baseline-ui/modal';
import { HeaderProvider } from '@luigiclaudio/ga-baseline-ui/header';
import SiteIntlProvider from './SiteIntlProvider';
import ColorToolsProvider from './context/ColorToolsProvider';
import SiteHeader from './pageLayout/SiteHeader';
import theme from '../styles/theme';
import AdsGlobalStyles from './adsContainers/AdsGlobalStyles';
import FeedbackProvider from './feedbackContext/FeedbackProvider';

const Layout = ({ currLocation, currTitle, children }) => {
    return (
        <SiteIntlProvider>
            <MountedProvider>
                <HeaderProvider>
                    <ColorToolsProvider>
                        <BaselineThemeProvider theme={theme}>
                            <AdsGlobalStyles />
                            <FeedbackProvider>
                                <ModalProvider>
                                    <SiteHeader
                                        logoPath="/media/site-logo-dark.svg"
                                        logoPathAlt="/media/site-logo-light.svg"
                                        title={currTitle}
                                        logoWidth={156}
                                        currentPath={currLocation?.pathname}
                                    >
                                        {children}
                                    </SiteHeader>
                                </ModalProvider>
                            </FeedbackProvider>
                        </BaselineThemeProvider>
                    </ColorToolsProvider>
                </HeaderProvider>
            </MountedProvider>
        </SiteIntlProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    currLocation: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    currTitle: PropTypes.string,
};

Layout.defaultProps = {
    currLocation: undefined,
    currTitle: undefined,
};

export default Layout;
