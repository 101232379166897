import siteBaseDarkTheme from './siteBaseDarkTheme';
import siteTooltipTheme from './siteTooltipTheme';

const siteTooltipDarkTheme = {
    tooltip: {
        ...siteTooltipTheme.tooltip,
        backgroundColor: siteBaseDarkTheme.base.colors.light,
        optionList: {
            item: {
                backgroundColor: siteBaseDarkTheme.base.colors.light,
                color: siteBaseDarkTheme.base.colors.dark,
                colorActive: siteBaseDarkTheme.base.colors.light,
                backgroundColorActive: siteBaseDarkTheme.base.colors.muted,
            },
        },
    },
};

export default siteTooltipDarkTheme;
