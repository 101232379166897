import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import withSizes from 'react-sizes';
import { transparentize } from 'polished';
import { useLocation } from '@gatsbyjs/reach-router';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { Header, HeaderOffset, HeaderContext } from '@luigiclaudio/ga-baseline-ui/header';
import { ModalContext } from '@luigiclaudio/ga-baseline-ui/modal';
import { SiteMetadata, useHasMounted, useLoading } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Identity, useUser } from '@luigiclaudio/ga-auth-theme';
import { ThemeContext } from 'styled-components';
import SiteHeaderThemeHelper from './SiteHeaderThemeHelper';
import AuthContentContainer from './AuthContentContainer';

const SiteHeader = ({
    logoPath,
    logoPathAlt,
    title,
    logoWidth,
    children,
    currentPath,
    isMobile,
}) => {
    const theme = useContext(ThemeContext);
    const { pathname } = useLocation();
    const intl = useIntl();
    const [user] = useUser();
    const siteUrl = process.env.GATSBY_SITE_BASE_URL;
    const hasMounted = useHasMounted();
    const [isLoading, load] = useLoading();
    const { logoutUser } = Identity.useIdentityContext();

    const { themeMode, toggleThemeMode } = useContext(ThemeModeContext);
    const { isOpen } = useContext(ModalContext);
    const { headerTheme, setHeaderTheme } = useContext(HeaderContext);

    const [isDarkModeOn, setIsDarkModeOn] = useState(false);

    useEffect(() => {
        if (themeMode === 'lightMode') {
            setIsDarkModeOn(false);
        }
        if (themeMode === 'darkMode') {
            setIsDarkModeOn(true);
        }
    }, [isMobile, themeMode, headerTheme]);

    const commonNavigationProperties = {
        btnType: 'link',
        skin: 'dark',
        size: isMobile ? 'lg' : 'md',
    };

    const navigationThemeMode = {
        label: intl.formatMessage({
            id: isDarkModeOn
                ? 'siteHeader.lightMode.button.label'
                : 'siteHeader.darkMode.button.label',
        }),
        icon: isDarkModeOn ? 'sun' : 'moon',
        onClick: () => toggleThemeMode(),
        ...commonNavigationProperties,
    };

    const commonNavigation = [
        {
            label: intl.formatMessage({
                id: 'siteHeader.harmonies.button.label',
            }),
            path: '/color-harmonies/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.wheel.button.label',
            }),
            path: '/color-wheel/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.details.button.label',
            }),
            path: '/color-details/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.mixer.button.label',
            }),
            path: '/color-mixer/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.tones.button.label',
            }),
            path: '/color-tones/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.tintsAndShades.button.label',
            }),
            path: '/tints-and-shades/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.lightenAndDarken.button.label',
            }),
            path: '/lighten-and-darken/',
            ...commonNavigationProperties,
        },
        {
            label: intl.formatMessage({
                id: 'siteHeader.theme.button.label',
            }),
            path: '/color-theme/',
            ...commonNavigationProperties,
        },
    ];

    const colorLibraryNavigation = {
        public: {
            label: intl.formatMessage({
                id: 'siteHeader.colorLibrary.button.label',
            }),
            path: '/try-color-library/',
            ...commonNavigationProperties,
        },
        client: {
            label: intl.formatMessage({
                id: 'siteHeader.colorLibrary.button.label',
            }),
            path: '/app/color-library/',
            ...commonNavigationProperties,
        },
    };

    const navigationList = {
        public: [
            ...commonNavigation,
            colorLibraryNavigation.public,
            {
                label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                icon: 'user-check',
                path: '/signIn/',
                skin: 'dark',
                btnType: 'link',
                size: 'md',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.signUp.button.label' }),
                icon: 'user-plus',
                path: '/signUp/',
                skin: 'dark',
                btnType: 'link',
                size: 'md',
            },
            navigationThemeMode,
        ],
        client: [
            ...commonNavigation,
            colorLibraryNavigation.client,
            {
                label: intl.formatMessage({ id: 'siteHeader.account.button.label' }),
                path: '/app/account/',
                icon: 'user',
                skin: 'dark',
                btnType: 'link',
                size: 'md',
            },
            navigationThemeMode,
        ],
    };
    const menuModalNavigationList = {
        public: [...commonNavigation, colorLibraryNavigation.public, navigationThemeMode],
        client: [...commonNavigation, colorLibraryNavigation.client, navigationThemeMode],
    };

    const menuModalSecondaryList = {
        public: [
            {
                label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                path: '/signIn/',
                icon: 'user-check',
                onClickDismiss: true,
                ...commonNavigationProperties,
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.signUp.button.label' }),
                path: '/signUp/',
                icon: 'user-plus',
                onClickDismiss: true,
                ...commonNavigationProperties,
            },
        ],
        client: [
            {
                label: intl.formatMessage({ id: 'siteHeader.account.button.label' }),
                path: '/app/account/',
                icon: 'user',
                onClickDismiss: true,
                ...commonNavigationProperties,
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.signOut.button.label' }),
                icon: 'log-out',
                loading: isLoading,
                onClickDismiss: true,
                onClick: () =>
                    load(logoutUser())
                        .then((res) => {
                            return res;
                        })
                        .catch((err) => {
                            throw err;
                        }),
                ...commonNavigationProperties,
            },
        ],
    };

    const isVertical = !isMobile;
    const scrollbarStylesColor =
        pathname === '/' ? transparentize(0.4, theme.base.grays.gray) : null;

    if (!hasMounted) {
        return null;
    }

    return (
        <>
            <SiteMetadata
                canonical={`${siteUrl}${currentPath}`}
                siteName={intl.formatMessage({ id: 'siteName' })}
                siteUrl={siteUrl}
                siteIcon="/media/logo-dark.png"
                siteDescription={intl.formatMessage({
                    id: 'siteLaunch.helmet.description',
                })}
                twitterHandle={intl.formatMessage({ id: 'twitterHandle' })}
                siteImage={`${siteUrl}/media/og-image.png`}
            />
            <Header
                isVertical={isVertical}
                isFullWidth
                logoPath={themeMode === 'lightMode' ? logoPath : logoPathAlt}
                logoPathAlt={logoPathAlt}
                logoLabel={intl.formatMessage({ id: 'siteName' })}
                title={title}
                logoWidth={logoWidth}
                navigationList={user && hasMounted ? navigationList.client : navigationList.public}
                menuModalNavigationList={
                    user && hasMounted
                        ? menuModalNavigationList.client
                        : menuModalNavigationList.public
                }
                menuModalSecondaryList={
                    user && hasMounted
                        ? menuModalSecondaryList.client
                        : menuModalSecondaryList.public
                }
                currentPath={currentPath}
                isBlurred={isOpen}
                isBackdropBlurred
                onClickLinkCallback={() => setHeaderTheme(themeMode)}
                scrollbarStylesColor={scrollbarStylesColor}
            />
            <HeaderOffset isFullHeight isBlurred={isOpen} isVertical={isVertical}>
                <SiteHeaderThemeHelper />
                <AuthContentContainer currentPath={currentPath} hasMounted={hasMounted}>
                    {children}
                </AuthContentContainer>
            </HeaderOffset>
        </>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isMobile: isSizes.mdView({ width }, { theme }),
    };
};

SiteHeader.propTypes = {
    logoPath: PropTypes.string,
    logoPathAlt: PropTypes.string,
    title: PropTypes.string,
    logoWidth: PropTypes.number,
    children: PropTypes.node.isRequired,
    currentPath: PropTypes.string,
    isMobile: PropTypes.bool,
};

SiteHeader.defaultProps = {
    logoPath: undefined,
    logoPathAlt: undefined,
    title: undefined,
    logoWidth: undefined,
    currentPath: undefined,
    isMobile: false,
};

export default withSizes(mapSizesToProps)(SiteHeader);
