const React = require('react');
const { Identity } = require('@luigiclaudio/ga-auth-theme');
const Layout = require('./src/components/layout').default;
const Apollo = require('./src/components/Apollo').default;
require('@fontsource/outfit/300.css');
require('@fontsource/outfit/400.css');
require('@fontsource/outfit/500.css');
require('@fontsource/outfit/600.css');
require('@fontsource/rubik/400.css');
require('@fontsource/rubik/600.css');

// eslint-disable-next-line react/prop-types
exports.wrapPageElement = ({ element, props }) => {
    // eslint-disable-next-line react/prop-types
    const { location } = props;
    return (
        <Identity.IdentityContextProvider url={process.env.GATSBY_SITE_BASE_URL}>
            <Apollo>
                <Layout currLocation={location}>{element}</Layout>
            </Apollo>
        </Identity.IdentityContextProvider>
    );
};

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.state?.queryString) {
        return false;
    }

    const currentPosition = getSavedScrollPosition(location);

    window.scrollTo(...(currentPosition || [0, 0]));

    return false;
};
