import React, { useContext, useEffect } from 'react';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { HeaderContext } from '@luigiclaudio/ga-baseline-ui/header';

const SiteHeaderThemeHelper = () => {
    const { themeMode } = useContext(ThemeModeContext);
    const { setHeaderTheme } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTheme(themeMode);
    }, [themeMode]);

    return <></>;
};

export default SiteHeaderThemeHelper;
