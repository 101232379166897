import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { headingStyles } from '@luigiclaudio/ga-baseline-ui/typography';

const AdsGlobalStyles = createGlobalStyle`
    [id^=carbonads_] {
        display: none;
    }
    #carbonads {
        border: 1px solid ${({ theme }) => theme.commerce.adsBanner.container.border};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
        display: flex; 
        font-family: ${({ theme }) => theme.typography.base.fontFamily};
        margin: initial;
        max-width: ${rem(330)};
        padding: initial;
        z-index: 100;
            
        a {
            color: inherit;
            text-decoration: none;
        }

        a:hover {
            color: inherit;
        }
    
        span {
            display: block;
            overflow: hidden;
            position: relative;
        }
    
        .carbon-wrap {
            display: flex;
            padding: ${rem(8)};
        }
        
        .carbon-img {
            align-items: center;
            display: flex;
            line-height: 1;
            margin: 0;
        }
    
        .carbon-img img {
            border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
            display: block;
            flex-shrink: 0;
            margin-right: ${rem(8)};
        }

        .carbon-text {
            ${headingStyles}
            align-items: center;
            display: flex;
            font-family: ${({ theme }) => theme.commerce.adsBanner.fontFamily};
            font-size: ${rem(14)};
            font-weight: 300;
            margin-bottom: ${rem(16)};
        }
        
        .carbon-poweredby {
            ${headingStyles}
            background-color: ${({ theme }) => theme.base.grays.grayLightest};
            border-top-left-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
            border-bottom-right-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
            bottom: 0;
            display: block;
            font-size: ${rem(9)};
            font-weight: 300;
            letter-spacing: ${rem(1)};
            line-height: 1;
            margin: 0;
            padding: ${rem(4)} ${rem(8)};
            position: absolute;
            right: 0;
            text-align: center;
            text-transform: uppercase;
        }
        
    }
`;

export default AdsGlobalStyles;
