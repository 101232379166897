import siteBaseTheme from './siteBaseTheme';

const siteModalTheme = {
    modal: {
        backgroundColor: siteBaseTheme.base.colors.light,
        footer: {
            backgroundColor: 'transparent',
        },
    },
};

export default siteModalTheme;
