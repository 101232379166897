import siteHeaderTheme from './siteHeaderTheme';

const siteHeaderDarkTheme = {
    header: {
        ...siteHeaderTheme.header,
        shadow: {
            horizontalShadow: '0 4px 8px rgba(0,0,0,0.4)',
            verticalShadow: '4px 0 8px rgba(0,0,0,0.4)',
        },
        backgroundColor: '#1c1c1c',
    },
};

export default siteHeaderDarkTheme;
