import siteBaseTheme from './siteBaseTheme';
import siteTheme from './siteTheme';

const siteBaseDarkTheme = {
    base: {
        ...siteBaseTheme.base,
        colors: {
            light: '#1c1c1c',
            dark: '#f1f1f1',
            muted: '#bababa',
        },
        body: {
            background: siteTheme.darkMode.site.stage.backgroundColor,
        },
    },
};

export default siteBaseDarkTheme;
