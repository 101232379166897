import siteButtonTheme from './siteButtonTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';

const siteButtonDarkTheme = {
    button: {
        ...siteButtonTheme.button,
        muted: {
            background: siteBaseDarkTheme.base.colors.muted,
            border: siteBaseDarkTheme.base.colors.muted,
        },
    },
};

export default siteButtonDarkTheme;
