import React, { useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import FeedbackContext from './FeedbackContext';
import FeedbackContainer from './FeedbackContainer';

const initialState = {
    feedbackOnStage: {
        label: undefined,
        type: undefined,
        show: false,
        icon: undefined,
        offsetTop: undefined,
    },
    setFeedbackOnStage: () => {},
};

const actions = {
    SET_FEEDBACK_ON_STAGE: 'SET_FEEDBACK_ON_STAGE',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_FEEDBACK_ON_STAGE:
            return {
                ...state,
                feedbackOnStage: action.feedbackOnStage,
            };
        default:
            return state;
    }
};

const FeedbackProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            feedbackOnStage: state.feedbackOnStage,
            setFeedbackOnStage: (feedbackOnStage) => {
                dispatch({ type: actions.SET_FEEDBACK_ON_STAGE, feedbackOnStage });
            },
        }),
        [state],
    );

    return (
        <FeedbackContext.Provider value={value}>
            <FeedbackContainer />
            {children}
        </FeedbackContext.Provider>
    );
};

FeedbackProvider.propTypes = {
    children: PropTypes.node,
};

FeedbackProvider.defaultProps = {
    children: undefined,
};

export default FeedbackProvider;
