import React, { useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

import ColorToolsContext from './ColorToolsContext';

const initialState = {
    colorOnStage: undefined,
    setColorOnStage: () => {},
    colorMixedOnStage: undefined,
    setColorMixedOnStage: () => {},
    stepsOnStage: undefined,
    setStepsOnStage: () => {},
    harmonyOnStage: undefined,
    setHarmonyOnStage: () => {},
    harmonyDominanceOnStage: undefined,
    setHarmonyDominanceOnStage: () => {},
    colorListOnStage: undefined,
    setColorListOnStage: () => {},
    userFromClient: undefined,
    setUserFromClient: () => {},
    allColorsUserFromClient: undefined,
    setAllColorsUserFromClient: () => {},
    selectedColors: undefined,
    setSelectedColors: () => {},
};

const actions = {
    SET_COLOR_ON_STAGE: 'SET_COLOR_ON_STAGE',
    SET_COLOR_MIXED_ON_STAGE: 'SET_COLOR_MIXED_ON_STAGE',
    SET_STEPS_ON_STAGE: 'SET_STEPS_ON_STAGE',
    SET_HARMONY_ON_STAGE: 'SET_HARMONY_ON_STAGE',
    SET_HARMONY_DOMINANCE_ON_STAGE: 'SET_HARMONY_DOMINANCE_ON_STAGE',
    SET_COLOR_LIST_ON_STAGE: 'SET_COLOR_LIST_ON_STAGE',
    SET_USER_FROM_CLIENT: 'SET_USER_FROM_CLIENT',
    SET_ALL_COLORS_USER_FROM_CLIENT: 'SET_ALL_COLORS_USER_FROM_CLIENT',
    SET_SELECTED_COLORS: 'SET_SELECTED_COLORS',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_COLOR_ON_STAGE:
            return {
                ...state,
                colorOnStage: action.colorValue,
            };
        case actions.SET_COLOR_MIXED_ON_STAGE:
            return {
                ...state,
                colorMixedOnStage: action.colorMixedValue,
            };
        case actions.SET_STEPS_ON_STAGE:
            return {
                ...state,
                stepsOnStage: action.steps,
            };
        case actions.SET_HARMONY_ON_STAGE:
            return {
                ...state,
                harmonyOnStage: action.harmony,
            };
        case actions.SET_HARMONY_DOMINANCE_ON_STAGE:
            return {
                ...state,
                harmonyDominanceOnStage: action.harmonyDominance,
            };
        case actions.SET_COLOR_LIST_ON_STAGE:
            return {
                ...state,
                colorListOnStage: action.colorListOnStage,
            };
        case actions.SET_USER_FROM_CLIENT:
            return { ...state, userFromClient: action.userFromClient };
        case actions.SET_ALL_COLORS_USER_FROM_CLIENT:
            return { ...state, allColorsUserFromClient: action.allColorsUserFromClient };
        case actions.SET_SELECTED_COLORS:
            return {
                ...state,
                selectedColors: action.selectedColors,
            };
        default:
            return state;
    }
};

const ColorToolsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            colorOnStage: state.colorOnStage,
            setColorOnStage: (colorValue) => {
                dispatch({ type: actions.SET_COLOR_ON_STAGE, colorValue });
            },
            colorMixedOnStage: state.colorMixedOnStage,
            setColorMixedOnStage: (colorMixedValue) => {
                dispatch({ type: actions.SET_COLOR_MIXED_ON_STAGE, colorMixedValue });
            },
            stepsOnStage: state.stepsOnStage,
            setStepsOnStage: (steps) => {
                dispatch({ type: actions.SET_STEPS_ON_STAGE, steps });
            },
            harmonyOnStage: state.harmonyOnStage,
            setHarmonyOnStage: (harmony) => {
                dispatch({ type: actions.SET_HARMONY_ON_STAGE, harmony });
            },
            harmonyDominanceOnStage: state.harmonyDominanceOnStage,
            setHarmonyDominanceOnStage: (harmonyDominance) => {
                dispatch({ type: actions.SET_HARMONY_DOMINANCE_ON_STAGE, harmonyDominance });
            },
            colorListOnStage: state.colorListOnStage,
            setColorListOnStage: (colorListOnStage) => {
                dispatch({ type: actions.SET_COLOR_LIST_ON_STAGE, colorListOnStage });
            },
            userFromClient: state.userFromClient,
            setUserFromClient: (userFromClient) => {
                dispatch({
                    type: actions.SET_USER_FROM_CLIENT,
                    userFromClient,
                });
            },
            allColorsUserFromClient: state.allColorsUserFromClient,
            setAllColorsUserFromClient: (allColorsUserFromClient) => {
                dispatch({
                    type: actions.SET_ALL_COLORS_USER_FROM_CLIENT,
                    allColorsUserFromClient,
                });
            },
            selectedColors: state.selectedColors,
            setSelectedColors: (selectedColors) => {
                dispatch({
                    type: actions.SET_SELECTED_COLORS,
                    selectedColors,
                });
            },
        }),
        [state],
    );

    return <ColorToolsContext.Provider value={value}>{children}</ColorToolsContext.Provider>;
};

ColorToolsProvider.propTypes = {
    children: PropTypes.node,
};

ColorToolsProvider.defaultProps = {
    children: undefined,
};

export default ColorToolsProvider;
