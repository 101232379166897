import siteBaseDarkTheme from './siteBaseDarkTheme';
import siteModalTheme from './siteModalTheme';

const siteModalDarkTheme = {
    modal: {
        ...siteModalTheme.modal,
        backgroundColor: siteBaseDarkTheme.base.colors.light,
        footer: {
            backgroundColor: 'transparent',
        },
    },
};

export default siteModalDarkTheme;
