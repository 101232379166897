import siteTheme from './siteTheme';

const siteBaseTheme = {
    base: {
        colors: {
            dark: '#191919',
            light: '#ffffff',
            muted: '#5b5b5b',
        },
        body: {
            background: siteTheme.lightMode.site.stage.backgroundColor,
        },
        scrollbar: {
            onBody: true,
            borderRadius: {
                track: 0,
                thumb: 2,
            },
        },
    },
};

export default siteBaseTheme;
