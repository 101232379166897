import siteBaseTheme from './siteBaseTheme';

const siteTooltipTheme = {
    tooltip: {
        backgroundColor: siteBaseTheme.base.colors.light,
        optionList: {
            item: {
                iconSize: 14,
                backgroundColor: siteBaseTheme.base.colors.light,
                color: siteBaseTheme.base.colors.dark,
                colorActive: siteBaseTheme.base.colors.light,
                backgroundColorActive: siteBaseTheme.base.colors.muted,
            },
        },
        zIndex: 2,
    },
};

export default siteTooltipTheme;
