import siteBaseTheme from './siteBaseTheme';

const siteButtonTheme = {
    button: {
        muted: {
            background: siteBaseTheme.base.colors.muted,
            border: siteBaseTheme.base.colors.muted,
        },
    },
};

export default siteButtonTheme;
