import { merge } from 'lodash';
import { authTheme, authDarkTheme } from '@luigiclaudio/ga-baseline-ui/auth';
import { alertTheme, alertDarkTheme } from '@luigiclaudio/ga-baseline-ui/alert';
import { baselineTheme } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { layoutTheme } from '@luigiclaudio/ga-baseline-ui/layout';
import { commerceTheme, commerceDarkTheme } from '@luigiclaudio/ga-baseline-ui/commerce';
import { baseTheme, baseDarkTheme } from '@luigiclaudio/ga-baseline-ui/base-theme';
import { buttonTheme, buttonDarkTheme } from '@luigiclaudio/ga-baseline-ui/button';
import { typographyTheme, typographyDarkTheme } from '@luigiclaudio/ga-baseline-ui/typography';
import { headerTheme, headerDarkTheme } from '@luigiclaudio/ga-baseline-ui/header';
import { siteLaunchTheme, siteLaunchDarkTheme } from '@luigiclaudio/ga-baseline-ui/site-launch';
import { footerTheme } from '@luigiclaudio/ga-baseline-ui/footer';
import { formTheme, formDarkTheme } from '@luigiclaudio/ga-baseline-ui/form';
import { loaderTheme, loaderDarkTheme } from '@luigiclaudio/ga-baseline-ui/helpers';
import { tooltipTheme, tooltipDarkTheme } from '@luigiclaudio/ga-baseline-ui/tooltip';
import { modalTheme, modalDarkTheme } from '@luigiclaudio/ga-baseline-ui/modal';
import { mailchimpSignUpTheme, mailchimpSignUpDarkTheme } from '@luigiclaudio/ga-mailchimp-signup';
import siteTheme from './siteTheme';
import siteTypographyTheme from './siteTypographyTheme';
import siteTypographyDarkTheme from './siteTypographyDarkTheme';
import siteBaseTheme from './siteBaseTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';
import siteHeaderTheme from './siteHeaderTheme';
import siteHeaderDarkTheme from './siteHeaderDarkTheme';
import siteSiteLaunchTheme from './siteSiteLaunchTheme';
import siteSiteLaunchDarkTheme from './siteSiteLaunchDarkTheme';
import siteLoaderTheme from './siteLoaderTheme';
import siteLoaderDarkTheme from './siteLoaderDarkTheme';
import siteFooterTheme from './siteFooterTheme';
import siteFooterDarkTheme from './siteFooterDarkTheme';
import siteButtonTheme from './siteButtonTheme';
import siteButtonDarkTheme from './siteButtonDarkTheme';
import siteTooltipTheme from './siteTooltipTheme';
import siteTooltipDarkTheme from './siteTooltipDarkTheme';
import siteModalTheme from './siteModalTheme';
import siteModalDarkTheme from './siteModalDarkTheme';
import siteAlertTheme from './siteAlertTheme';
import siteAlertDarkTheme from './siteAlertDarkTheme';

const theme = {
    lightMode: merge(baselineTheme.lightMode, {
        alert: merge(alertTheme, { ...siteAlertTheme.alert }),
        base: merge(baseTheme.base, {
            ...siteBaseTheme.base,
        }),
        button: merge(buttonTheme?.button, {
            ...siteButtonTheme.button,
        }),
        commerce: merge(commerceTheme.commerce),
        form: merge(formTheme.form),
        typography: merge(typographyTheme.typography, {
            ...siteTypographyTheme.typography,
        }),
        header: merge(headerTheme.header, {
            ...siteHeaderTheme.header,
        }),
        footer: merge(footerTheme.footer, {
            ...siteFooterTheme.footer,
        }),
        layout: merge(layoutTheme.layout, {
            maxSiteWidth: 1920,
        }),
        auth: merge(authTheme.auth),
        loader: merge(loaderTheme.loader, {
            ...siteLoaderTheme.loader,
        }),
        mailchimpSignUp: merge(mailchimpSignUpTheme.mailchimpSignUp),
        modal: merge(modalTheme.modal, {
            ...siteModalTheme.modal,
        }),
        siteLaunch: merge(siteLaunchTheme.siteLaunch, {
            ...siteSiteLaunchTheme.siteLaunch,
        }),
        tooltip: merge(tooltipTheme.tooltip, {
            ...siteTooltipTheme.tooltip,
        }),
        ...siteTheme.lightMode,
    }),
    darkMode: merge(baselineTheme.darkMode, {
        alert: merge(alertDarkTheme, { ...siteAlertDarkTheme.alert }),
        base: merge(baseDarkTheme.base, {
            ...siteBaseDarkTheme.base,
        }),
        button: merge(buttonDarkTheme?.button, {
            ...siteButtonDarkTheme.button,
        }),
        commerce: merge(commerceDarkTheme.commerce),
        form: merge(formDarkTheme.form),
        typography: merge(typographyDarkTheme.typography, {
            ...siteTypographyDarkTheme.typography,
        }),
        header: merge(headerDarkTheme.header, {
            ...siteHeaderDarkTheme.header,
        }),
        footer: merge(footerTheme.footer, {
            ...siteFooterDarkTheme.footer,
        }),
        layout: merge(layoutTheme.layout, {
            maxSiteWidth: 1920,
        }),
        auth: merge(authDarkTheme.auth),
        loader: merge(loaderDarkTheme.loader, {
            ...siteLoaderDarkTheme.loader,
        }),
        mailchimpSignUp: merge(mailchimpSignUpDarkTheme.mailchimpSignUp),
        modal: merge(modalDarkTheme.modal, {
            ...siteModalDarkTheme.modal,
        }),
        siteLaunch: merge(siteLaunchDarkTheme.siteLaunch, {
            ...siteSiteLaunchDarkTheme.siteLaunch,
        }),
        tooltip: merge(tooltipDarkTheme.tooltip, {
            ...siteTooltipDarkTheme.tooltip,
        }),
        ...siteTheme.darkMode,
    }),
};

export default theme;
