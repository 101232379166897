const siteTheme = {
    lightMode: {
        site: {
            stage: {
                backgroundColor: '#fcfcfc',
                height: '100vh',
                paddingTop: 64,
            },
            colorPicker: {
                width: 260,
            },
            footer: {
                madeWithLove: {
                    icon: 'heart',
                    iconColor: '#e52000',
                },
            },
            colorVariant: {
                minHeight: 96,
                minWidth: 12,
            },
            colorMixer: {
                colorResults: {
                    colorVariant: {
                        height: 368,
                    },
                },
            },
            colorDetails: {
                colorResults: {
                    colorVariant: {
                        height: 240,
                    },
                    colorComplementaryVariant: {
                        height: 80,
                    },
                },
            },
            home: {
                intro: {
                    viewportHeight: 72,
                    customBgColor: '#2d9281',
                },
                title: {
                    textShadow: '0 1px 8px rgba(255,255,255, 0.15)',
                },
            },
            library: {
                isFavorite: {
                    backgroundColor: '#ffffff',
                },
            },
            feedback: {
                icon: {
                    add: 'plus',
                    select: 'plus-square',
                    deselect: 'x',
                    remove: 'x-square',
                    copied: 'check',
                    notCopied: 'alert-circle',
                    mainColor: 'flag',
                    replace: 'repeat',
                },
            },
            colorList: {
                item: {
                    oneColumn: {
                        gapV: 16,
                        gapH: 16,
                        width: 160,
                        height: 160,
                        gridMargin: 32,
                    },
                    multiColumn: {
                        gapV: 32,
                        gapH: 32,
                        width: 240,
                        height: 160,
                        gridMargin: 32,
                    },
                },
            },
        },
    },
    darkMode: {
        site: {
            stage: {
                backgroundColor: '#121212',
                height: '100vh',
                paddingTop: 64,
            },
            colorPicker: {
                width: 260,
            },
            footer: {
                madeWithLove: {
                    icon: 'heart',
                    iconColor: '#f28f7f',
                },
            },
            colorVariant: {
                minHeight: 96,
                minWidth: 12,
            },
            colorMixer: {
                colorResults: {
                    colorVariant: {
                        height: 368,
                    },
                },
            },
            colorDetails: {
                colorResults: {
                    colorVariant: {
                        height: 240,
                    },
                    colorComplementaryVariant: {
                        height: 80,
                    },
                },
            },
            home: {
                intro: {
                    viewportHeight: 72,
                    customBgColor: '#f44c68',
                },
                title: {
                    textShadow: '0 1px 8px rgba(255,255,255, 0.15)',
                },
            },
            library: {
                isFavorite: {
                    backgroundColor: '#ffffff',
                },
            },
            feedback: {
                icon: {
                    add: 'plus',
                    select: 'plus-square',
                    deselect: 'x',
                    remove: 'x-square',
                    copied: 'check',
                    notCopied: 'alert-circle',
                    mainColor: 'flag',
                    replace: 'repeat',
                },
            },
            colorList: {
                item: {
                    oneColumn: {
                        gapV: 16,
                        gapH: 16,
                        width: 160,
                        height: 160,
                        gridMargin: 32,
                    },
                    multiColumn: {
                        gapV: 32,
                        gapH: 32,
                        width: 240,
                        height: 160,
                        gridMargin: 32,
                    },
                },
            },
        },
    },
};

export default siteTheme;
