exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-account-js": () => import("./../../../src/pages/app/Account.js" /* webpackChunkName: "component---src-pages-app-account-js" */),
  "component---src-pages-app-color-library-js": () => import("./../../../src/pages/app/ColorLibrary.js" /* webpackChunkName: "component---src-pages-app-color-library-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-color-details-js": () => import("./../../../src/pages/color-details.js" /* webpackChunkName: "component---src-pages-color-details-js" */),
  "component---src-pages-color-harmonies-js": () => import("./../../../src/pages/color-harmonies.js" /* webpackChunkName: "component---src-pages-color-harmonies-js" */),
  "component---src-pages-color-mixer-js": () => import("./../../../src/pages/color-mixer.js" /* webpackChunkName: "component---src-pages-color-mixer-js" */),
  "component---src-pages-color-theme-js": () => import("./../../../src/pages/color-theme.js" /* webpackChunkName: "component---src-pages-color-theme-js" */),
  "component---src-pages-color-tones-js": () => import("./../../../src/pages/color-tones.js" /* webpackChunkName: "component---src-pages-color-tones-js" */),
  "component---src-pages-color-wheel-js": () => import("./../../../src/pages/color-wheel.js" /* webpackChunkName: "component---src-pages-color-wheel-js" */),
  "component---src-pages-confirm-account-js": () => import("./../../../src/pages/confirmAccount.js" /* webpackChunkName: "component---src-pages-confirm-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-more-color-details-js": () => import("./../../../src/pages/learn-more/color-details.js" /* webpackChunkName: "component---src-pages-learn-more-color-details-js" */),
  "component---src-pages-lighten-and-darken-js": () => import("./../../../src/pages/lighten-and-darken.js" /* webpackChunkName: "component---src-pages-lighten-and-darken-js" */),
  "component---src-pages-list-of-installed-packages-js": () => import("./../../../src/pages/list-of-installed-packages.js" /* webpackChunkName: "component---src-pages-list-of-installed-packages-js" */),
  "component---src-pages-mailing-list-js": () => import("./../../../src/pages/mailing-list.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recover-account-js": () => import("./../../../src/pages/recoverAccount.js" /* webpackChunkName: "component---src-pages-recover-account-js" */),
  "component---src-pages-recover-password-js": () => import("./../../../src/pages/recoverPassword.js" /* webpackChunkName: "component---src-pages-recover-password-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/signIn.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/signUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tints-and-shades-js": () => import("./../../../src/pages/tints-and-shades.js" /* webpackChunkName: "component---src-pages-tints-and-shades-js" */),
  "component---src-pages-try-color-library-js": () => import("./../../../src/pages/try-color-library.js" /* webpackChunkName: "component---src-pages-try-color-library-js" */)
}

